import axios from "axios";
import React, {useCallback, useEffect, useRef, useState} from "react";
import NotificationAlert from "react-notification-alert";
import {
    Col,
    Row,
    Input,
    Card,
    Button,
    Spinner,
    CardBody,
    CardFooter,
    CardHeader,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import Index from "../components/Main/auditorAndArtiste";

function AuditorProAndArtistes() {

    const notificationAlert = useRef()
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [userToShow, setUserToShow] = useState({});

    const checkLastUserSignIn = useCallback(async () => {
        !loading && setLoading(true)
        await axios.get('/admin/users/last').then((response) => {
            let data = response.data;
            if (data.length)
                setUserToShow(data[0])
            else setUserToShow({})
            setUsers(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const searchUser = async (e) => {
        let str = e.target.value;
        if (str) {
            setLoading(true)
            await axios.get('/admin/users/search_profile/' + str).then((response) => {
                let data = response.data;
                if (data.length)
                    setUserToShow(data[0])
                else setUserToShow({})
                setUsers(data)
                setLoading(false)
            })
        } else await checkLastUserSignIn();
    }

    useEffect(() => {
        checkLastUserSignIn().then(() => null);
    }, [checkLastUserSignIn]);


    useEffect(() => {

    }, []);

    return (
        <div className="content">
            <NotificationAlert ref={notificationAlert} />
            <Row>
                <Col md="6">
                    <Card className="card-stats">
                        <CardHeader>
                            <form>
                                <InputGroup className="no-border">
                                    <Input placeholder="Chercher l'utilisateur, email, nom, prenom ..."
                                           onChange={(e) => searchUser(e)}
                                           style={{width: '80%'}}/>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <i className="nc-icon nc-zoom-split ml-2"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </form>
                        </CardHeader>
                        <CardBody>
                            <Row className="mb-3 text-uppercase text-muted">
                                <Col md="5" className="text-left">
                                    email
                                </Col>
                                <Col md="5" className="text-left">
                                    type
                                </Col>
                            </Row>
                            {!users.length
                                ? <p className="text-left">Pas de utilisateur a afficher</p>
                                : users.map((users, index) =>
                                    <Row key={index}>
                                        <Col md="5" className="text-left pt-3">
                                            {users?.my_profile.email}
                                        </Col>
                                        <Col md="5" className="text-left pt-3">
                                            {users?.role}
                                        </Col>
                                        <Col md="2" className="text-left">
                                            <Button
                                                onClick={() => setUserToShow(users)}
                                                color={userToShow.user_id === users.user_id ? "success" : "dark"}>
                                                <i className="nc-icon nc-single-02"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            }
                        </CardBody>
                        <CardFooter>
                            <hr/>
                            <div className="stats row justify-content-between pl-3 pr-3">
                                <div className="text-uppercase">
                                    {loading
                                        ? <Spinner animation="grow" size="sm"/>
                                        : <i className="fas fa-sync-alt" onClick={() => checkLastUserSignIn()}/>}
                                </div>
                                <i className="far fa-clock text-warning pt-1"/>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="6">
                    {Object.keys(userToShow).length !== 0
                        ? <Index data={userToShow}/>
                        : <p className="text-center">Aucun auditeur</p>
                    }
                </Col>
            </Row>
        </div>
    );
}

export default AuditorProAndArtistes;
