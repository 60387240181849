import axios from "axios";
import dayjs from "dayjs";
import React, {useEffect, useRef, useState} from "react";
import NotificationAlert from "react-notification-alert";
import {useSelector} from "react-redux";
import {Card, Nav, TabContent, CardBody, Col, NavItem, NavLink, Row, TabPane, Spinner} from "reactstrap";
import {copy} from "../../../tools/tools";
import Details from "../services/details";
import Reservation from "./reservation";

function ContactCard(props) {

    const {contactCard, currency} = props;
    const notificationAlert = useRef()
    const [service, setService] = useState(null);
    const [tabActive, setTabActive] = useState(1);
    const [loading, setLoading] = useState(false);
    const [refIndexCopied, setRefIndexCopied] = useState(null);
    const status = useSelector(state => state.global.status);

    const checkService = async () => {
        setLoading(true)
        await axios.get('/artist_services/' + contactCard?.reservation.services_id).then((response) => {
            let data = response.data;
            setService(data.service)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (tabActive === 3 && !service ) {
            checkService().then(r => null)
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [tabActive, service]);

    return (
        <Card className="card-stats">
            <NotificationAlert ref={notificationAlert} />
            <Nav tabs>
                <NavItem className="cursor-pointer">
                    <NavLink
                        className={tabActive === 1 ? "" : "active"}
                        onClick={() => setTabActive(1)}
                    >
                        Prise de contact
                    </NavLink>
                </NavItem>
                <NavItem className="cursor-pointer">
                    <NavLink
                        className={tabActive === 2 ? "" : "active"}
                        onClick={() => setTabActive(2)}
                    >
                        Réservation associé
                    </NavLink>
                </NavItem>
                <NavItem className="cursor-pointer">
                    <NavLink
                        className={tabActive === 3 ? "" : "active"}
                        onClick={() => setTabActive(3)}
                    >
                        Fiche de préstation associé
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={tabActive.toString()}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <Card className="card-stats m-2">
                                <CardBody>
                                    {contactCard?.payment &&
                                    <Row className="border-bottom mb-3">
                                        <Col md="4" className="text-break">
                                            <small>Référence</small>
                                        </Col>
                                        <Col md="8">
                                            <p className="font-weight-bold text-right">
                                                {contactCard?.payment.reference.substring(0, 9) + "****"}
                                                {refIndexCopied === contactCard?.id ?
                                                    <i className="ml-2 nc-icon nc-check-2 text-success"/>
                                                    : <i className="ml-2 nc-icon nc-single-copy-04 cursor-pointer"
                                                         onClick={(e) =>
                                                             contactCard?.payment && copy(
                                                                 contactCard?.id,
                                                                 contactCard?.payment.reference,
                                                                 setRefIndexCopied,
                                                                 notificationAlert
                                                             )
                                                    }/>}
                                                </p>
                                        </Col>
                                    </Row>}
                                    <Row className="border-bottom mb-3">
                                        <Col md="4" className="text-break">
                                            <small>L'AUDITEUR PRO</small>
                                        </Col>
                                        <Col md="8">
                                            <p className="font-weight-bold text-right">
                                                {contactCard?.reservation?.auditor_name + ' '}
                                                (Pour {contactCard.name + ' ' + contactCard.lastname})
                                            </p>
                                        </Col>
                                    </Row>
                                    {contactCard?.payment &&
                                    <Row className="border-bottom mb-3">
                                        <Col md="4" className="text-break">
                                            <small>Email (de l'auditeur pro)</small>
                                        </Col>
                                        <Col md="8">
                                            <p className="font-weight-bold text-right">{contactCard?.payment?.email}</p>
                                        </Col>
                                    </Row>}
                                    <Row className="border-bottom mb-3">
                                        <Col md="4" className="text-break">
                                            <small>ARTISTE</small>
                                        </Col>
                                        <Col md="8">
                                            <p className="font-weight-bold text-right">
                                                {contactCard?.reservation?.artist_name}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="border-bottom mb-3">
                                        <Col md="4" className="text-break">
                                            <small>Frais de contact</small>
                                        </Col>
                                        <Col md="8">
                                            <p className="font-weight-bold text-right">
                                                {contactCard?.amount ? contactCard?.amount + currency : '*Offert'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="border-bottom mb-3">
                                        <Col md="4" className="text-break">
                                            <small>Status</small>
                                        </Col>
                                        <Col md="8">
                                            <p className="font-weight-bold text-right">
                                                {status[contactCard?.status]}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="border-bottom mb-3">
                                        <Col md="4" className="text-break">
                                            <small>Envoyée le</small>
                                        </Col>
                                        <Col md="8">
                                            <p className="font-weight-bold text-right">
                                                {dayjs(contactCard?.created_at).format("DD-MM-YYYY à HH:MM")}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="border-bottom">
                                        <Col md="4" className="text-break">
                                            <small>Dernière modification</small>
                                        </Col>
                                        <Col md="8">
                                            <p className="font-weight-bold text-right">
                                                {dayjs(contactCard?.modified_at).format("DD-MM-YYYY à HH:MM")}
                                            </p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col sm="12">
                            <Reservation reservation={contactCard?.reservation}/>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    {loading
                        ? <div className="text-center overflow-auto">
                        <Spinner animation="grow" size="m" className="mr-2"/>
                    </div> : <Details serviceCountry={currency} service={service}/>
                    }
                </TabPane>
            </TabContent>
        </Card>
    );
}

export default ContactCard;
