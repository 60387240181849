import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Card, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap";

function ContactCardOfTheMonth() {
    const [loading, setLoading] = useState(false);
    const [pending, setPending] = useState(0);
    const [declined, setDeclined] = useState(0);
    const [accepted, setAccepted] = useState(0);
    const [potentialFee, setPotentialFee] = useState(0);

    const checkUserSignInOfTheMonth = useCallback(async () => {
        setLoading(true)
        await axios.get('/admin/contact_cards/income_month').then((response) => {
            let data = response.data;
            setPotentialFee(data['potential_fee'])
            setPending(data['contact_card_pending'])
            setDeclined(data['contact_card_declined'])
            setAccepted(data['contact_card_accepted'])
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    useEffect(() => {
        checkUserSignInOfTheMonth().then(() => null);
    }, [checkUserSignInOfTheMonth]);

    useEffect(() => {

    }, []);

    return (
        <Card className="card-stats">
            <CardBody>
                <p className="text-center text-uppercase">Prise de contact du mois</p>
                <hr />
                <Row>
                    <Col md="6" xs="5">
                        <div className="row justify-content-between ml-1 mr-1">
                            <em className="mr-1">Accepté</em>
                            <p className="text-success">{accepted}</p>
                        </div>
                        <div className="row justify-content-between ml-1 mr-1">
                            <em className="mr-1">En attente</em>
                            <p className="text-warning">{pending}</p>
                        </div>
                        <div className="row justify-content-between ml-1 mr-1">
                            <em className="mr-1">Décliné</em>
                            <p className="text-danger">{declined}</p>
                        </div>
                        <div className="row justify-content-between ml-1 mr-1">
                            <em className="mr-1">&nbsp;</em>
                            <p>&nbsp;</p>
                        </div>
                        <div className="row justify-content-between ml-1 mr-1">
                            <em className="mr-1">&nbsp;</em>
                            <p>&nbsp;</p>
                        </div>
                    </Col>
                    <Col md="6" xs="7">
                        <div className="numbers">
                            <p className="card-category">Potentiel</p>
                            <h5 tag="p"><em>{potentialFee || 0}€</em></h5>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <hr/>
                <div className="stats row justify-content-between pl-3 pr-3">
                    <div className="text-uppercase cursor-pointer">
                        {loading
                            ? <Spinner className="mr-2" animation="grow" size="sm"/>
                            : <i className="fas fa-sync-alt" onClick={() => checkUserSignInOfTheMonth()}/>}
                    </div>
                    <i className="nc-icon nc-globe text-warning pt-1"/>
                </div>
            </CardFooter>
        </Card>
    );
}

export default ContactCardOfTheMonth;
