import React from "react";
// react plugin used to create charts
// reactstrap components
import {Col, Row,} from "reactstrap";
// core components
import ArtistsRanking from "../stats/artistsRanking";
import IncomeOfTheMonth from "../stats/incomeOfTheMonth";
import ReservationDiagrams from "../stats/reservationDiagrams";
import ContactCardOfTheMonth from "../stats/ContactCardOfTheMonth";
import UserBehaviors from "../stats/userBehaviors";
import UserStats from "../stats/userStats";

function Dashboard() {
    return (
        <div className="content">
            <Row>
                <Col md="9">
                    <Row>
                        <Col lg="4" md="6" sm="6">
                            <UserStats/>
                        </Col>
                        <Col lg="4" md="6" sm="6">
                            <ContactCardOfTheMonth/>
                        </Col>
                        <Col lg="4" md="6" sm="6">
                            <IncomeOfTheMonth/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <ReservationDiagrams/>
                        </Col>
                        <Col md="12" style={{marginBottom: -20}}>
                            <UserBehaviors/>
                        </Col>
                    </Row>
                </Col>
                <Col md="3">
                    <ArtistsRanking/>
                </Col>
            </Row>
        </div>
    );
}

export default Dashboard;
