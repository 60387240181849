import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {Card, CardBody, CardFooter, CardHeader, CardTitle, Input, Spinner} from "reactstrap";

const options = {
    plugins: {
        legend: {display: false},
    },
}

const reservations = "réservations"
const contactCards = "prises de contact"
const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

function ReservationDiagrams() {

    const [tag, setTag] = useState("");
    const [loading, setLoading] = useState(false);
    const [declined, setDeclined] = useState([]);
    const [accepted, setAccepted] = useState([]);
    const [currentYear, setCurrentYear] = useState(1900 + new Date().getYear());

    const dashboardNASDAQChart = () => {
        return {
            labels: labels,
            datasets: [{
                label: 'Prise de contact accepté',
                data: accepted,
                fill: false,
                borderColor: 'green',
                tension: 0.1
            }, {
                label: 'Prise de contact refuser',
                data: declined,
                fill: false,
                borderColor: 'red',
                tension: 0.1
            }]
        };
    };

    const changeYear = useCallback(async (nbr) => {
        await setCurrentYear(currentYear + nbr)
        await tag === reservations ? checkRYear(currentYear + nbr) : checkCYear(currentYear + nbr)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [currentYear]);

    const checkRYear = useCallback(async (year) => {
        setTag(reservations)
        setLoading(true)
        if (!year)
            setCurrentYear(1900 + new Date().getYear())
        let newYear = year || currentYear;
        await axios.get('/admin/reservation/years_stat/' + newYear).then((response) => {
            let data = response.data;
            setDeclined(data['reservation_declined'])
            setAccepted(data['reservation_accepted'])
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const checkCYear = useCallback(async (year) => {
        setTag(contactCards)
        setLoading(true)
        if (!year)
            setCurrentYear(1900 + new Date().getYear())
        let newYear = year || currentYear;
        await axios.get('/admin/contact_cards/years_stat/' + newYear).then((response) => {
            let data = response.data;
            setDeclined(data['contact_card_declined'])
            setAccepted(data['contact_card_accepted'])
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    useEffect(() => {
        checkRYear().then(() => null);
    }, [checkRYear]);

    useEffect(() => {

    }, []);
    return (
        <Card className="card-chart">
            <CardHeader>
                <CardTitle tag="h5">Diagramme des
                    <div className="col-4 p-0 m-2">
                        <Input type="select"
                               onChange={(e) => e.target.value === reservations ? checkRYear() : checkCYear()}>
                            <option value={reservations} defaultChecked>{reservations}</option>
                            <option value={contactCards}>{contactCards}</option>
                        </Input>
                    </div>
                </CardTitle>
                <p className="card-category">
                    Statistique de l'année {currentYear}
                </p>
            </CardHeader>
            <CardBody>
                <Line
                    data={dashboardNASDAQChart()}
                    options={options}
                    width={400}
                    height={100}
                />
            </CardBody>
            <CardFooter>
                <div className="chart-legend">
                    <i className="fa fa-circle text-success m-1"/> Acceptée
                    <i className="fa fa-circle text-danger m-1"/> Declinée
                </div>
                <hr/>
                <div className="stats row justify-content-between pl-3 pr-3">
                    <div className="text-uppercase cursor-pointer">
                        {loading
                            ? <Spinner animation="grow" size="sm"/>
                            : <i className="fas fa-sync-alt" onClick={() => checkRYear()}/>}
                    </div>
                    <div className="text-dark">
                        <i className="nc-icon nc-minimal-left cursor-pointer" onClick={() => changeYear(-1)}/>
                        <i className="nc-icon nc-simple-delete"/>
                        <i className="nc-icon nc-minimal-right cursor-pointer" onClick={() => changeYear(+1)}/>
                    </div>
                </div>
            </CardFooter>
        </Card>
    );
}

export default ReservationDiagrams;
