import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Collapse, Container, Navbar, NavbarBrand, NavbarToggler, Spinner,} from "reactstrap";

import routes from "routes.js";

function Header(props) {

    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [color, setColor] = useState("transparent");
    const sidebarToggle = useRef();
    const location = useLocation();
    const history = useHistory();

    const toggle = () => {
        if (isOpen) {
            setColor("transparent");
        } else {
            setColor("dark");
        }
        setIsOpen(!isOpen);
    };

    const getBrand = () => {
        let brandName = "Default Brand";
        routes.map((prop, key) => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                brandName = prop.name;
            }
            return null;
        });
        return brandName;
    };

    const openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        sidebarToggle.current.classList.toggle("toggled");
    };

    // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && isOpen) {
            setColor("dark");
        } else {
            setColor("transparent");
        }
    };

    const Logout = async () => {
        setLoading(true)
        await axios.delete("users/logout").then(async () => {
            await localStorage.removeItem('creativeAdminToken')
            await setLoading(false)
            axios.defaults.headers.common['Isl-Token'] = null
            history.push('/auth')
        });
    }

    useEffect(() => {
        window.addEventListener("resize", updateColor.bind(this));
    });

    useEffect(() => {
        if (
            window.innerWidth < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            sidebarToggle.current.classList.toggle("toggled");
        }
    }, [location]);

    return (
        // add or remove classes depending if we are on full-screen-maps page or not
        <Navbar
            color={
                props.location.pathname.indexOf("full-screen-maps") !== -1
                    ? "dark"
                    : color
            }
            expand="lg"
            className={
                props.location.pathname.indexOf("full-screen-maps") !== -1
                    ? "navbar-absolute fixed-top"
                    : "navbar-absolute fixed-top " +
                    (color === "transparent" ? "navbar-transparent " : "")
            }
        >
            {loading
                ? <Spinner animation="grow" size="sm"/>
                : <i style={{cursor: "pointer"}}
                     className="nc-icon nc-button-power m-1 text-danger"
                     onClick={() => Logout()}/>}

            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button
                            type="button"
                            ref={sidebarToggle}
                            className="navbar-toggler"
                            onClick={() => openSidebar()}
                        >
                            <span className="navbar-toggler-bar bar1"/>
                            <span className="navbar-toggler-bar bar2"/>
                            <span className="navbar-toggler-bar bar3"/>
                        </button>
                    </div>
                    <NavbarBrand href="/">{getBrand()}</NavbarBrand>
                </div>
                <NavbarToggler onClick={toggle}>
                    <span className="navbar-toggler-bar navbar-kebab"/>
                    <span className="navbar-toggler-bar navbar-kebab"/>
                    <span className="navbar-toggler-bar navbar-kebab"/>
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar className="justify-content-end">
                    {/*<form>*/}
                    {/*  <InputGroup className="no-border">*/}
                    {/*    <Input placeholder="Search..." />*/}
                    {/*    <InputGroupAddon addonType="append">*/}
                    {/*      <InputGroupText>*/}
                    {/*        <i className="nc-icon nc-zoom-split ml-2" />*/}
                    {/*      </InputGroupText>*/}
                    {/*    </InputGroupAddon>*/}
                    {/*  </InputGroup>*/}
                    {/*</form>*/}
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
