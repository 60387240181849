import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import NotificationAlert from "react-notification-alert";
import {useSelector} from "react-redux";
import {Button, Card, Col, Nav, NavItem, Row, TabContent} from "reactstrap";
import {notify} from "../../../tools/tools";
import CreateContactCard from "../contactCards/createContactCard";
import Details from "./details";
import Switch from '@mui/material/Switch';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Tooltip from "@mui/material/Tooltip";

function Service(props) {

    const {service, checkLastServices} = props
    const countries = useSelector(state => state.global.countries);
    const notificationAlert = useRef()
    const [serviceCountry, setServiceCountry] = useState({});
    const [smShow, setSmShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const currency = serviceCountry?.currencies?.symbol || serviceCountry
    const AntSwitch = styled(Switch)(({theme}) => ({
        width: 28,
        height: 16,
        padding: 0,
        marginTop: 5,
        marginRight: 5,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#17dcb8' : '#18ffdc',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    }));

    const changeTest = async () => {
        await axios.put('/admin/services/switch_test/' + service?.id.toString()).then(async () => {
            await checkLastServices()
            notify('success', "Vous avez changer le statut de " + service?.title, notificationAlert)
        })
    }

    const changeHidden = async () => {
        await axios.put('/admin/services/switch_hidden/' + service?.id.toString()).then(async () => {
            await checkLastServices()
            notify('success', "Vous avez changer la visibilité de " + service?.title, notificationAlert)
        })
    }

    const removeService = async () => {
        await axios.delete('/artist_services/delete/' + service?.id.toString()).then(async () => {
            await checkLastServices()
            setSmShow(false)
            notify('success', "Vous avez supprimer " + service?.title, notificationAlert)
        })
    }

    useEffect(() => {
        let t_country = countries?.filter((country) => country.name === service['country'])[0]
        t_country && setServiceCountry(t_country)
    }, [countries, service]);

    return (
        <Card>
            <NotificationAlert ref={notificationAlert}/>

            <Modal size="sm" show={smShow} aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Body>
                    Supprimer {service?.title} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" color="danger" onClick={() => setSmShow(false)}>
                        Non
                    </Button>
                    <Button variant="primary" color="success" onClick={() => removeService()}>Oui</Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showModal} aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Body>
                    <h5 className="text-center">
                        Créer une prise de contact
                        <strong className="text-success">&nbsp;{service?.artist_name}</strong>
                    </h5>
                </Modal.Body>
                <Modal.Footer>
                    <CreateContactCard
                        service={service}
                        serviceCountry={serviceCountry}
                        t_notificationAlert={notificationAlert}
                        closeModal={() => setShowModal(false)}/>
                </Modal.Footer>
            </Modal>

            <Nav tabs>
                <NavItem className="row col">

                    <Tooltip title="Assigner une prise de contact" placement="top">
                        <div className="row justify-content-center m-2 cursor-pointer"
                             onClick={() => currency && setShowModal(true)}>
                            <AddIcon sx={{color: 'blue!important'}}/>&nbsp;Contact
                        </div>
                    </Tooltip>

                    <Divider orientation="vertical" className="ml-2 mr-2" variant="middle" flexItem/>

                    <Tooltip title={
                        service?.test
                            ? "Cette fiche est un test"
                            : "Fiche en production, Veuillez basculer pour le mode Test"
                    } placement="top">
                        <div className="row justify-content-center m-2">
                            <AntSwitch
                                checked={service?.test}
                                onChange={() => changeTest()}
                                inputProps={{'aria-label': 'ant design'}}/>
                            <strong>Test</strong>
                        </div>
                    </Tooltip>

                    <Divider orientation="vertical" className="ml-2 mr-2" variant="middle" flexItem/>
                    <Tooltip title={
                        service?.hidden
                            ? "Cette fiche est un non visible"
                            : "Cette fiche est visible pour tous"
                    } placement="top">
                        <IconButton onClick={() => changeHidden()}>
                            {service?.hidden
                                ? <VisibilityOffIcon/>
                                : <VisibilityIcon/>}
                        </IconButton>
                    </Tooltip>

                    <Divider orientation="vertical" className="ml-2 mr-2" variant="middle" flexItem/>
                    <Tooltip title="Supprimer cette fiche KantoBiz" placement="top">
                        <IconButton onClick={() => setSmShow(true)}>
                            <DeleteIcon sx={{color: 'red!important'}}/>
                        </IconButton>
                    </Tooltip>

                </NavItem>
            </Nav>
            <TabContent>
                <Row>
                    <Col sm="12" className="mt-1 mr-2">
                        <Details service={service} currency={currency} serviceCountry={serviceCountry}/>
                    </Col>
                </Row>
            </TabContent>
        </Card>
    );
}

export default Service;
