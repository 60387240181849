import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";

function ArtistWallet(props) {
    const {wallet, currencyCode} = props;
    const [currency, setCurrency] = useState('');
    const countries = useSelector(state => state.global.countries);

    useEffect(() => {
        let t_country = countries?.filter((c) => c.currencies.code.toLowerCase() === currencyCode.toLowerCase())[0]
        t_country && setCurrency(t_country?.currencies.symbol)
    }, [countries, currencyCode]);

    return (
        <div>
            {wallet && Object.keys(wallet).length !== 0 &&
            <Card className="card-stats m-2">
                <CardBody>
                    <Row>
                        <Col md="4" className="text-break">
                            Solde
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {wallet?.balance + currency}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            Dernière modification
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {dayjs(wallet?.modified_at).format("DD-MM-YYYY")}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            crée le
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {dayjs(wallet?.created_at).format("DD-MM-YYYY")}
                            </p>
                        </Col>
                    </Row>
                </CardBody>
            </Card>}
        </div>
    );
}

export default ArtistWallet;
