import axios from "axios";
import React, {useEffect, useState, useCallback, useRef} from "react";
import Modal from "react-bootstrap/Modal";
import NotificationAlert from "react-notification-alert";
import {Button, CardBody, CardHeader, CardTitle, Col, Row, Spinner} from "reactstrap";
import {notify} from "../tools/tools";
import p_logo from 'assets/img/logo.png';

function AdminMembers(props) {

    const notificationAlert = useRef()
    const {userConnectedRight} = props;
    const [allAdmin, setAllAdmin] = useState([]);
    const [loading, setLoading] = useState(false);
    const [smShow, setSmShow] = useState(false);
    const [adminToRemove, setAdminToRemove] = useState({});

    const checkAllUserAdmin = useCallback(async () => {
        setLoading(true)
        await axios.get('/admin/users/all').then((response) => {
            let data = response.data;
            setAllAdmin(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const removeAdmin = () => {
        axios.put("/admin/users/delete_admin/" + adminToRemove.id.toString()).then(async response => {
            await checkAllUserAdmin();
            await setSmShow(false);
            notify('success', "L'utilisateur n'est plus admin", notificationAlert)
        })
    }

    const AlertRemoveAdmin = (value) => {
        setAdminToRemove(value);
        setSmShow(true)
    }

    useEffect(() => {
        checkAllUserAdmin().then(() => null);
    }, [checkAllUserAdmin]);

    useEffect(() => {

    }, []);

    return (
        <div>
            <NotificationAlert ref={notificationAlert} />
            <Modal size="sm" show={smShow} aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Body>
                    Enlever {adminToRemove.name} de sa fonction d'admin ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" color="danger" onClick={() => setSmShow(false)}>
                        Non
                    </Button>
                    <Button variant="primary" disabled={loading} color="success" onClick={() => removeAdmin()}>
                        Oui
                    </Button>
                </Modal.Footer>
            </Modal>
            <CardHeader>
                <CardTitle>
                    {loading
                        ? <Spinner animation="grow" size="sm" className="mr-2"/>
                        : <i className="fas fa-sync-alt mr-2 cursor-pointer" onClick={() => checkAllUserAdmin()}/>}
                    Équipe(s) Admin
                </CardTitle>
            </CardHeader>
            <CardBody>
                <ul className="list-unstyled team-members">
                    {allAdmin.map((value, index) =>
                        <li key={index}>
                            <Row>
                                <div className="col-2 pt-2">
                                    <img
                                        alt="..."
                                        className="img-circle img-responsive"
                                        src={value.photo ? value.photo : p_logo}
                                    />
                                </div>
                                <Col md="8">
                                    {value.name} <br/>
                                    <small className="text-muted">{value.email}</small>
                                </Col>
                                <Col className="text-right" md="2">
                                    <Button
                                        className="btn-round btn-icon"
                                        color="danger"
                                        outline
                                        size="sm"
                                        disabled={value.right === 2 || userConnectedRight === 1 || loading}
                                        onClick={() => AlertRemoveAdmin(value)}
                                    >
                                        <i className="nc-icon nc-simple-delete"/>
                                    </Button>
                                </Col>
                            </Row>
                        </li>)}
                </ul>
            </CardBody>
        </div>
    );
}

export default AdminMembers;
