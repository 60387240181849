import React, {useEffect, useState} from "react";
import {Card, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import ArtistBank from "./artistBank";
import ArtistServices from "./artistServices";
import ArtistWallet from "./artistWallet";
import BookingContactList from "./bookingContactList";
import Profile from "./profile";
import ContactDemand from "./contactDemand";

function Index(props) {
    const {data} = props
    const [tabActive, setTabActive] = useState(1);

    useEffect(() => {
        setTabActive(1);
    }, [data]);

    useEffect(() => {

    }, []);

    return (
        <Card style={{margin: 2}}>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={tabActive === 1 ? "" : "active"}
                        onClick={() => setTabActive(1)}
                    >
                        Profil
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={tabActive === 2 ? "" : "active"}
                        onClick={() => setTabActive(2)}
                    >
                        Prise(s) de contact
                    </NavLink>
                </NavItem>
                {data?.role !== 'professional_auditor' &&
                <NavItem>
                    <NavLink
                        className={tabActive === 3 ? "" : "active"}
                        onClick={() => setTabActive(3)}
                    >
                        Demandes
                    </NavLink>
                </NavItem>}
                {data?.role !== 'professional_auditor' &&
                <NavItem>
                    <NavLink
                        className={tabActive === 4 ? "" : "active"}
                        onClick={() => setTabActive(4)}
                    >
                        Prestations
                    </NavLink>
                </NavItem>}
                {data?.role !== 'professional_auditor' &&
                <NavItem>
                    <NavLink
                        className={tabActive === 5 ? "" : "active"}
                        onClick={() => setTabActive(5)}
                    >
                        Banque
                    </NavLink>
                </NavItem>}
                {data?.role !== 'professional_auditor' &&
                <NavItem>
                    <NavLink
                        className={tabActive === 6 ? "" : "active"}
                        onClick={() => setTabActive(6)}
                    >
                        Portefeuille
                    </NavLink>
                </NavItem>}
            </Nav>

            <TabContent activeTab={tabActive.toString()}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <Profile profile={data?.my_profile}
                                     condition={data?.conditions}
                                     user_id={data?.user_id}
                                     keyActivate={data?.key_activation}
                                     banned={data?.banned}/>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col sm="12">
                            <BookingContactList contactCards={data.artist_contacted}/>
                        </Col>
                    </Row>
                </TabPane>
                {data?.role !== 'professional_auditor' &&
                <TabPane tabId="3">
                    <Row>
                        <Col sm="12">
                            <ContactDemand contactCards={data?.auditor_contacts}/>
                        </Col>
                    </Row>
                </TabPane>}
                {data?.role !== 'professional_auditor' &&
                <TabPane tabId="4">
                    <Row>
                        <Col sm="12">
                            <ArtistServices services={data?.user_services}/>
                        </Col>
                    </Row>
                </TabPane>}
                {data?.role !== 'professional_auditor' &&
                <TabPane tabId="5">
                    <Row>
                        <Col sm="12">
                            <ArtistBank bank={data?.banking}/>
                        </Col>
                    </Row>
                </TabPane>}
                {data?.role !== 'professional_auditor' &&
                <TabPane tabId="6">
                    <Row>
                        <Col sm="12">
                            <ArtistWallet wallet={data?.wallet} currencyCode={data['my_profile']['currency']}/>
                        </Col>
                    </Row>
                </TabPane>}
            </TabContent>
        </Card>
    );
}

export default Index;
