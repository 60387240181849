import axios from "axios";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Row,
    Col,
    CardHeader,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    CardBody,
    Button,
    CardFooter, Card, Spinner
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Payment from "../components/Main/payment";

function PaymentHistory() {

    const notificationAlert = useRef()
    const [loading, setLoading] = useState(true)
    const [payments, setPayments] = useState([]);
    const [paymentToShow, setPaymentToShow] = useState({});

    const checkLastPayments = useCallback(async () => {
        setLoading(true)
        await axios.get('/admin/payments/last').then((response) => {
            let data = response.data;
            if (data.length)
                setPaymentToShow(data[0])
            else setPaymentToShow({})
            setPayments(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const searchPayment = async (e) => {
        let str = e.target.value;
        if (str) {
            setLoading(true)
            await axios.get('/admin/payments/search/' + str).then((response) => {
                let data = response.data;
                if (data.length)
                    setPaymentToShow(data[0])
                else setPaymentToShow({})
                setPayments(data)
                setLoading(false)
            })
        }

    }

    useEffect(() => {
        checkLastPayments().then(() => null);
    }, [checkLastPayments]);

    useEffect(() => {

    }, []);

    return (
        <div className="content">
            <NotificationAlert ref={notificationAlert} />
            <Row>
                <Col md="6">
                    <Card className="card-stats">
                        <CardHeader>
                            <form>
                                <InputGroup className="no-border">
                                    <Input placeholder="Chercher le payment, reference ..."
                                           onChange={(e) => searchPayment(e)}
                                           style={{width: '80%'}}/>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <i className="nc-icon nc-zoom-split ml-2"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </form>
                        </CardHeader>
                        <CardBody>
                            <Row className="mb-3 text-uppercase text-muted">
                                <Col md="5" className="text-left">
                                    Reference
                                </Col>
                                <Col md="5" className="text-left">
                                    email
                                </Col>
                            </Row>
                            {!payments.length
                                ? <p className="text-left">Pas de payment a afficher</p>
                                : payments.map((payment, index) =>
                                    <Row key={index}>
                                        <Col md="5" className="text-left pt-3">
                                            {payment.reference}
                                        </Col>
                                        <Col md="4" className="text-left pt-3">
                                            {payment.email}
                                        </Col>
                                        <Col md="3" className="text-left">
                                            <Button
                                                onClick={() => setPaymentToShow(payment)}
                                                color={paymentToShow.id === payment.id ? "success" : "dark"}>
                                                afficher
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            }
                        </CardBody>
                        <CardFooter>
                            <hr/>
                            <div className="stats row justify-content-between pl-3 pr-3">
                                <div className="text-uppercase">
                                    {loading
                                        ? <Spinner animation="grow" size="sm"/>
                                        : <i className="fas fa-sync-alt" onClick={() => checkLastPayments()}/>}
                                </div>
                                <i className="far fa-clock text-warning pt-1"/>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="6">
                    {Object.keys(paymentToShow).length !== 0
                        ? <Payment payment={paymentToShow}/>
                        : <p className="text-center">Pas de payment a afficher</p>
                    }
                </Col>
            </Row>
        </div>
    );
}

export default PaymentHistory;
