import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {outputHtPrice} from "../../../tools/tools";

function CustomRow(props) {
    const {row, currency} = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row._type}
                </TableCell>
                <TableCell align="right">
                    <em>{row.amount + currency}</em>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Détails sur la facture
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {row.invoice.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell align="left" component="th" scope="row">
                                                {historyRow.title}
                                            </TableCell>
                                            <TableCell align="right">
                                                <em>{historyRow.amount + currency}</em>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

CustomRow.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

function AmountInfos(props) {
    const {reservationAmount, contactAmount, currency} = props
    const [rows, setRows] = useState([]);

    const createData = (_type, amount) => {
        const {serviceFee, artistFee} =  outputHtPrice(amount);

        return {
            _type,
            amount,
            invoice: [
                {
                    title: "Frais de service",
                    amount: serviceFee
                },
                {
                    title: "Frais de l'artiste",
                    amount: artistFee
                }
            ],
        };
    }

    useEffect(() => {
        if (reservationAmount) {
            let tmp = [createData('Reservation', reservationAmount)]
            contactAmount && tmp.push(createData('Contact', contactAmount))
            setRows(tmp)
        }
    }, [reservationAmount, contactAmount]);

    return (
        <TableContainer component={Paper} className="mb-2">
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Type(s)</TableCell>
                        <TableCell align="right">Montant</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <CustomRow key={row.name} row={row} currency={currency}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default AmountInfos;
