import dayjs from "dayjs";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {CardBody, Card, Col, Row} from "reactstrap";
import {currencyByAddress} from "../../../tools/tools";

function Reservation(props) {
    const {reservation} = props;
    const {status, countries} = useSelector(state => state.global);

    useEffect(() => {

    }, []);

    return (
        <Card className="card-stats m-2">
            <CardBody>
                {reservation?.invoice &&
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Facture</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{reservation?.invoice?.substring(0, 10) + " ..."}</p>
                    </Col>
                </Row>}
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Status</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right text-warning">{status[reservation?.status]}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Addresse</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{reservation?.address}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Évènement</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right text-danger">{reservation?.event}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Date de l'évènement</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{dayjs(reservation?.event_date).format("DD-MM-YYYY")}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Heure d'arrivée d'artiste(s)</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">
                            <small>avant&nbsp;-&nbsp;</small>
                            {dayjs(reservation?.service_time_to_arrived).format("DD-MM-YYYY à HH:MM")}
                        </p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Fin de prestation</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">
                            <small>après&nbsp;-&nbsp;</small>
                            {dayjs(reservation?.service_time_to_finished).format("DD-MM-YYYY à HH:MM")}
                        </p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Prix de la reservation</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right text-success">{reservation?.total_amount + currencyByAddress(reservation.address, countries)}</p>
                    </Col>
                </Row>
                <Row className="border-bottom">
                    <Col md="4" className="text-break">
                        <small>Envoyée le</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{dayjs(reservation?.created_at).format("DD-MM-YYYY")}</p>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default Reservation;
