const initState = {
    countries: [],
    status: {
        'pending': 'En attente',
        'accepted': 'Accepté',
        'declined': 'Réfusé',
    }
};

const Global = (state = initState, action) => {
    switch (action.type) {
        case "ADD_COUNTRIES":
            return {
                ...state,
                countries: action.data
            };
        default:
            return state;
    }
};

export default Global;
