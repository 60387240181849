import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Card, Col, CardTitle, Row, CardBody, CardFooter} from "reactstrap";

function ArtistServices(props) {
    const {services} = props
    const countries = useSelector(state => state.global.countries);

    const handleCurrency = (_country) => {
        let t_country = countries?.filter((country) => country.name === _country)[0]
        return t_country?.currencies.symbol
    }

    useEffect(() => {

    }, []);

    return (
        <div className="overflow-auto m-2">
            <Row className="justify-content-center">
                {services?.map((service, index) =>
                    <Col md="6">
                        <Card className="card-stats" key={index}>
                            <img
                                alt="..."
                                style={{height: 150, width: '100%'}}
                                src={service?.galleries[0]}
                            />
                            <CardTitle className="text-center mt-2 text-uppercase">
                                {service.title}
                            </CardTitle>

                            <CardBody>
                                <Row>
                                    <Col md="4" className="text-break">
                                        Pays
                                    </Col>
                                    <Col md="8">
                                        <p className="font-weight-bold text-primary text-right">
                                            {service?.country}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4" className="text-break">
                                        Cachée
                                    </Col>
                                    <Col md="8">
                                        <p className="font-weight-bold text-primary text-right">
                                            {service?.hidden ? 'Oui' : 'Non'}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4" className="text-break">
                                        politique
                                    </Col>
                                    <Col md="8">
                                        <p className="font-weight-bold text-primary text-right">
                                            {service?.refund_policy}
                                        </p>
                                    </Col>
                                </Row>
                            </CardBody>

                            <CardFooter className="text-center text-danger text-uppercase">
                                {service.price + handleCurrency(service.country)}
                            </CardFooter>
                        </Card>
                    </Col>
                )}
            </Row>
        </div>
    );
}

export default ArtistServices;
