import axios from "axios"
import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {Route, useHistory} from "react-router-dom";
import AdminLayout from "./layouts/Admin";
import {addCountries} from "./reducer";
import Login from "./views/Login";
import "./assets/css/app.css";

function App() {

    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(false);

    useEffect(() => {
        axios.defaults.headers.common['admin'] = "true"
        axios.defaults.headers.common['Language'] = "fr"
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"
        axios.defaults.headers.common['Content-Type'] = 'application/json'
        axios.get("countries_and_cities/all_countries").then(async r => {
            await dispatch(addCountries(r.data))
            axios.get("users/if_token_valid").then((resp) => {
                history.push('/admin/dashboard');
            }).catch(() => {
                localStorage.removeItem('creativeAdminToken')
                history.push('/auth')
            });
        })

        return () => {
            isMounted.current = true
        };
    }, [history, dispatch]);

    return (
        <div>
            <Route exact path="/(|auth)" component={() => <Login/>}/>
            <Route path="/admin" render={(props) => <AdminLayout {...props} />}/>
        </div>
    );
}

export default App;
