import dayjs from "dayjs";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import {currencyByAddress} from "../../tools/tools";

function Payment(props) {
    const {payment} = props;
    const countries = useSelector(state => state.global.countries);
    const currency = currencyByAddress(payment?.country, countries)

    useEffect(() => {

    }, []);

    return (
        <Card className="card-stats">
            <CardBody>
                <Row>
                    <Col md="4" className="text-break">
                        Reference de payment
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-danger text-right">{payment?.reference}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        Adresse de facturation
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{payment?.address}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        Ville
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{payment?.city}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        Code Postal
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{payment?.postal_code}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        email de facturation
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-danger text-right">{payment?.email}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        Addresse IP
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{payment?.ip_address || 0}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        Payé
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-primary text-right">{payment?.paid ? 'Oui' : 'Non'}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        nom
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{payment?.name || 'pas de nom'}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        Prénom
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{payment?.lastname || 'pas de prénom'}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        Derniere modification
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{dayjs(payment?.modified_at).format("DD-MM-YYYY")}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        Date de payment
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{dayjs(payment?.created_at).format("DD-MM-YYYY")}</p>
                    </Col>
                </Row>

                <Row>
                    <Col md="4" className="text-break">
                        Remboursée
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{payment?.refund ? 'Oui' : 'Non'}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        Montant remboursée
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-warning text-right">
                            {payment?.refunded || 0}{currency}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        Service fee
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{payment?.service_fee ? 'Oui' : 'Non'}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        tva
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-warning text-right">
                            {payment?.tva || 0}
                            {currency}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        montant de l'artiste
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-warning text-right">
                            {payment?.artist_amount || 0}
                            {currency}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        montant du service
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-warning text-right">
                            {payment?.isl_amount || 0}
                            {currency}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="text-break">
                        montant total
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right text-success">
                            {payment?.total_amount || 0}
                            {currency}
                        </p>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default Payment;
