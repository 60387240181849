import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Card, CardBody, CardFooter, CardHeader, Spinner, Row, Col} from "reactstrap";

function ArtistsRanking() {
    const [loading, setLoading] = useState(true);
    const [ranking, setRanking] = useState([]);
    const [currentYear, setCurrentYear] = useState(1900 + new Date().getYear());

    const changeYear = useCallback(async (nbr) => {
        await setCurrentYear(currentYear + nbr)
        await checkArtistRanking(currentYear + nbr)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [currentYear]);

    const checkArtistRanking = useCallback(async (year) => {
        !loading && setLoading(true)
        if (!year)
            setCurrentYear(1900 + new Date().getYear())
        let newYear = year || currentYear;
        await axios.get('/admin/reservation/artist_ranking/' + newYear).then((response) => {
            let data = response.data;
            setRanking(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    useEffect(() => {
        checkArtistRanking().then(() => null);
    }, [checkArtistRanking]);

    useEffect(() => {

    }, []);

    return (
        <Card className="card-stats" style={{height: '100%'}}>
            <CardHeader>
                <p className="text-center text-uppercase">Classement de l'année {currentYear}</p>
                <hr/>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="4" className="text-left">
                        <p>RANG</p>
                    </Col>
                    <Col md="4" className="text-center">
                        <p>NOM</p>
                    </Col>
                    <Col md="4" className="text-right">
                        <p>NB.R</p>
                    </Col>
                </Row>
                {!ranking?.length
                    ? <p className="text-center">Pas d'artiste a afficher</p>
                    : ranking?.map((value, index) =>
                        <Row key={index}>
                            <Col md="2" className="text-left text-success">
                                {index + 1}
                            </Col>
                            <Col md="8" className="text-center font-weight-bold">
                                {value?.artist?.name}
                            </Col>
                            <Col md="2" className="text-right font-weight-bold text-danger">
                                {value?.service_realized}
                            </Col>
                        </Row>
                    )
                }
            </CardBody>
            <CardFooter>
                <hr/>
                <div className="stats row justify-content-between pl-3 pr-3">
                    <div className="text-uppercase cursor-pointer">
                        {loading
                            ? <Spinner animation="grow" size="sm"/>
                            : <i className="fas fa-sync-alt" onClick={() => checkArtistRanking()}/>}
                    </div>
                    <div className="text-dark">
                        <i className="nc-icon nc-minimal-left cursor-pointer" onClick={() => changeYear(-1)}/>
                        <i className="nc-icon nc-simple-delete"/>
                        <i className="nc-icon nc-minimal-right cursor-pointer" onClick={() => changeYear(+1)}/>
                    </div>
                </div>
            </CardFooter>
        </Card>
    );
}

export default ArtistsRanking;
