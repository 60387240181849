import axios from "axios";
import React, {useCallback, useEffect, useRef, useState} from "react";
import NotificationAlert from "react-notification-alert";
import {useSelector} from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner
} from "reactstrap";
import Service from "../components/Main/services/service";

function Services() {

    const notificationAlert = useRef()
    const [loading, setLoading] = useState(false);
    const [services, setReservations] = useState([]);
    const [serviceToShow, setServiceToShow] = useState({});
    const countries = useSelector(state => state.global.countries);

    const checkLastServices = useCallback(async () => {
        setLoading(true)
        await axios.get('/admin/services/last').then((response) => {
            let data = response.data;
            if (data.length)
                setServiceToShow(data[0])
            else setServiceToShow({})
            setReservations(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const searchReservation = async (e) => {
        let str = e.target.value;
        if (str) {
            setLoading(true)
            await axios.get('/admin/services/search/' + str).then((response) => {
                let data = response.data;
                if (data.length)
                    setServiceToShow(data[0])
                else setServiceToShow({})
                setReservations(data)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        checkLastServices().then(() => null);
    }, [checkLastServices]);

    useEffect(() => {

    }, []);

    return (
        <div className="content">
            <NotificationAlert ref={notificationAlert}/>
            <Row>
                <Col md="6">
                    <Card className="card-stats">
                        <CardHeader>
                            <form>
                                <InputGroup className="no-border">
                                    <Input placeholder="Chercher la fiche préstation, nom de l'artiste, email, ..."
                                           onChange={(e) => searchReservation(e)}
                                           style={{width: '80%'}}/>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <i className="nc-icon cursor-pointer nc-zoom-split ml-2"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </form>
                        </CardHeader>
                        <CardBody>
                            <Row className="mb-3 text-uppercase text-muted">
                                <Col md="3" className="text-left">
                                    <small>Titre</small>
                                </Col>
                                <Col md="3" className="text-left">
                                    <small>Pays</small>
                                </Col>
                                <Col md="3" className="text-left">
                                    <small>Prix</small>
                                </Col>
                            </Row>
                            {!services.length
                                ? <p className="text-left">Pas de service a afficher</p>
                                : services.map((service, index) =>
                                    <Row key={index}>
                                        <Col md="3" className="text-left pt-3">
                                            {service.title}
                                        </Col>
                                        <Col md="3" className="text-left pt-3">
                                            {service.country}
                                        </Col>
                                        <Col md="3" className="text-left pt-3">
                                            {service.price}&nbsp;
                                            {countries?.filter((country) => country.name === service.country)[0]?.currencies?.symbol}
                                        </Col>
                                        <Col md="3" className="text-left">
                                            <Button
                                                onClick={() => setServiceToShow(service)}
                                                color={serviceToShow.id === service.id ? "success" : "dark"}>
                                                voir
                                                <i className="nc-icon nc-shop text-red ml-1"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            }
                        </CardBody>
                        <CardFooter>
                            <hr/>
                            <div className="stats row justify-content-between pl-3 pr-3">
                                <div className="text-uppercase">
                                    {loading
                                        ? <Spinner animation="grow" size="sm"/>
                                        : <i className="fas fa-sync-alt cursor-pointer"
                                             onClick={() => checkLastServices()}/>}
                                </div>
                                <i className="far fa-clock text-warning pt-1"/>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="6">
                    <Service service={serviceToShow} checkLastServices={checkLastServices}/>
                </Col>
            </Row>
        </div>
    );
}

export default Services;
