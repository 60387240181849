import axios from "axios";
import dayjs from "dayjs";
import React, {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import NotificationAlert from "react-notification-alert";
import ChatMsg from '@mui-treasury/components/chatMsg/ChatMsg';
import {useSelector} from "react-redux";
import {Button, Card, CardBody, CardHeader, Col, Row, Spinner} from "reactstrap";
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import {useChromeInputStyles} from '@mui-treasury/styles/input/chrome';
import {
    axios_bot,
    changeFields,
    checkErrorMessage,
    notify,
    reservationChatInfo,
    switchAnswers
} from "../../../tools/tools";
import {ImpulseSpinner} from "react-spinners-kit";


function Main() {

    const toScroll = useRef()
    const chatMainAlert = useRef()
    const isMounted = useRef(false);
    const styles = useChromeInputStyles();
    const [textMsg, setTextMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [contactCards, setContactCards] = useState([]);
    const [activeModal, setActiveModal] = useState(false);
    const [contactToChat, setContactToChat] = useState(null);
    const countries = useSelector(state => state.global.countries);
    const [msgList, setMsgList] = useState([
        <ChatMsg
            avatar={''}
            messages={[
                'Bonjour je suis Pap Izuka.',
                "Je vous donnerais tout les informations par rapport a l'événement en cours.",
                "Toutes information partagez ici ne sont visible qu'entre l'artiste et l'auditeur.",
                "Veuillez associer une prise de contact pour commencer le test.",
            ]}
        />
    ]);

    const assignContactCard = async (contactCard) => {
        setContactToChat(contactCard)
        setActiveModal(false)
        await setMsgList([...msgList, <ChatMsg avatar={''} messages={[
            <p className="mb-0">
                Vous avez assigné une prise de contact qui a été fait par
                <strong>&nbsp;{contactCard?.reservation?.auditor_name}.</strong>
            </p>,
            <p className="mb-0">
                Cette prise de contact à été envoyer le&nbsp;
                <strong>{dayjs(contactCard?.created_at).format("DD-MM-YYYY")}</strong>&nbsp;
                à l'encontre de l'artiste&nbsp;<strong>{contactCard?.reservation?.artist_name}</strong>&nbsp;
                pour un événement de type&nbsp;<strong>{contactCard?.reservation?.event}</strong>.
            </p>,
            reservationChatInfo(contactCard?.reservation, countries),
            "Maintenant demander moi ce que vous voulez savoir à propos de la réservation ou de l'événement.",
        ]}/>])
        toScroll.current.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
    }

    const getBotResponse = async () => {
        setLoading(true)
        if (contactToChat) {
            let _axios = axios_bot();
            let _textMsg = textMsg
            setTextMsg('')
            let tmp = [...msgList, <ChatMsg side={'right'} avatar={''} messages={[_textMsg]}/>]
            await setMsgList(tmp)
            toScroll.current.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
            _axios.post('/bot/predict/check_bot_response/' + contactToChat.id, {'user_message': _textMsg}).then(async (resp) => {
                setLoading(false)
                let data = resp?.data
                setMsgList([...tmp, switchAnswers(contactToChat, data, countries)])
                toScroll.current.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
            }).catch((error) => {
                setLoading(false)
                let errorMessage = checkErrorMessage(error);
                notify('danger', errorMessage, chatMainAlert)
            })
        } else {
            let tmp = [...msgList, <ChatMsg side={'right'} avatar={''} messages={[textMsg]}/>]
            await setMsgList(tmp)
            setTextMsg('');
            setMsgList([...tmp, <ChatMsg avatar={''} messages={[
                "Veuillez associer une prise de contact pour commencer le test."
            ]}/>])
            setLoading(false)
            toScroll.current.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
        }
    }

    const getContactCards = async () => {
        setLoading(true)
        axios.get('/admin/contact_cards/per_page/1').then((resp) => {
            setLoading(false)
            setContactCards(resp.data.data)
        }).catch((error) => {
            setLoading(false)
            let errorMessage = checkErrorMessage(error);
            notify('danger', errorMessage, chatMainAlert)
        })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && textMsg.length) {
            getBotResponse().then(r => null)
        }
    }

    useEffect(() => {

        activeModal && getContactCards().then(r => null)

        return () => {
            isMounted.current = true
        };
    }, [activeModal]);

    return (
        <Card className="card-stats mb-0" style={{height: '85vh'}}>
            <NotificationAlert ref={chatMainAlert}/>

            <Modal show={activeModal} size="m" className="mt-2">
                <Modal.Header>
                    <h5 className="col text-center">Choisir une prise de contact</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center overflow-auto" style={{height: 200}}>
                        {loading
                            ? <Spinner animation="grow" size="m" className="mr-2"/>
                            : !contactCards?.length
                                ? <p>Pas de resultat a afficher</p>
                                : contactCards?.map((value, index) =>
                                    <Row key={index + 'search'}>
                                        <Col className="ml-auto mr-auto" lg="7" md="6" xs="6">
                                            <div>
                                                <em><small>{value?.reservation?.auditor_name}</small></em>
                                                &nbsp;à contacté&nbsp;
                                                <strong>{value?.reservation?.artist_name}</strong>
                                            </div>
                                        </Col>
                                        <Col className="mr-auto" lg="2">
                                            <i className="nc-icon nc-simple-add text-success"
                                               onClick={() => assignContactCard(value)}/>
                                        </Col>
                                    </Row>
                                )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-danger" onClick={() => setActiveModal(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            <CardHeader>
                <p className="text-center text-uppercase">
                    Discussion Testing&nbsp;
                </p>
                <hr/>
            </CardHeader>
            <CardBody>
                <Button style={{marginTop: '-1px', marginBottom: 15}}
                        className={contactToChat ? "col btn-info" : "col secondary"}
                        onClick={() => setActiveModal(true)}>
                    {contactToChat ? "Une prise de contact a été associé" : "Associer une prise de contact"}
                </Button>
                <div className="m-1 overflow-auto" style={{height: '55vh'}}>
                    <p style={{whiteSpace: 'pre-line'}} ref={toScroll}>
                        {msgList?.map((value, index) => value)}
                        <div style={{margin: 12, paddingTop: 10}} hidden={!loading}>
                            <ImpulseSpinner size={50} frontColor="#f5f5f5"/>
                        </div>
                    </p>
                </div>
            </CardBody>
            <div className="ml-3 mb-3 mr-3">
                <hr/>
                <InputBase
                    onKeyPress={(e) => handleKeyPress(e)}
                    value={textMsg}
                    className={styles.root}
                    style={{width: '100%'}}
                    placeholder={'Ecrivez votre message ici ...'}
                    onChange={(e) => changeFields(setTextMsg, e)}
                    startAdornment={
                        <IconButton className={styles.icon}>
                            <InfoOutlined/>
                        </IconButton>
                    }
                    endAdornment={
                        <IconButton className={styles.icon} onClick={() => textMsg.length && getBotResponse()}>
                            <small>Envoyez</small>&nbsp;<i className="nc-icon nc-send"/>
                        </IconButton>
                    }
                />
            </div>
        </Card>
    );
}

export default Main;
