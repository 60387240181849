import {CardElement} from "@stripe/react-stripe-js";
import React, {useEffect, useRef} from "react";
import '../../../assets/css/creditCard.css'

const cardElementOption = {
    hidePostalCode: true,
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: '#8CB8B8',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '20px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#fce883',
            },
            '::placeholder': {
                color: '#8CB8B8',
            },
        },
        invalid: {
            iconColor: '#ED1C24',
            color: '#ED1C24',
        },
    },
};

function CreditCard() {

    const isMounted = useRef(false);

    useEffect(() => {

        return () => {
            isMounted.current = true
        };
    }, []);

    return (
        <div className="container">
            <div className="card-holder">
                <div className="card-box bg-news">
                    <div className="flex text-center">
                        <div className="img-box">
                            <i className="icon m-2 icon-cc-stripe text-red s-36"/>
                            <i className="icon m-2 icon-cc-amex text-red s-36"/>
                            <i className="icon m-2 icon-cc-mastercard text-red s-36"/>
                            <i className="icon m-2 icon-cc-visa text-red s-36"/>
                        </div>
                        <div className="card-details">
                            <div className="form-group">
                                <label className="mb-3 text-light">Carte de crédit ou de débit</label>
                                <CardElement options={cardElementOption}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreditCard;
