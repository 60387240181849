import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {Card, CardBody, CardFooter, CardHeader, CardTitle, Spinner} from "reactstrap";

const options = {
    plugins: {
        legend: {display: false},
        tooltip: {enabled: false},
    },
    scales: {
        y: {
            ticks: {
                color: "#9f9f9f",
                beginAtZero: false,
                maxTicksLimit: 5,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        x: {
            barPercentage: 1.6,
            grid: {
                drawBorder: false,
                display: false,
            },
            ticks: {
                padding: 20,
                color: "#9f9f9f",
            },
        },
    },
};

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

function FeeDiagrams() {
    const [loading, setLoading] = useState(true);
    const [yearStat, setYearStat] = useState([]);
    const [currentYear, setCurrentYear] = useState(1900 + new Date().getYear());
    const FeeDiagramOfYear = () => {
        return {
            labels: labels,
            datasets: [
                {
                    labels: "Revenues",
                    borderColor: "#6bd098",
                    backgroundColor: "#6bd098",
                    fill: true,
                    data: yearStat,
                    tension: 0.1
                }
            ],
        }
    }

    const changeYear = useCallback(async (nbr) => {
        await setCurrentYear(currentYear + nbr)
        await checkIncomeByYear(currentYear + nbr)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [currentYear]);

    const checkIncomeByYear = useCallback(async (year) => {
        !loading && setLoading(true)
        if (!year)
            setCurrentYear(1900 + new Date().getYear())
        let newYear = year || currentYear;
        await axios.get('/admin/reservation/income_of_year/' + newYear).then((response) => {
            let data = response.data;
            setYearStat(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    useEffect(() => {
        checkIncomeByYear().then(() => null);
    }, [checkIncomeByYear]);

    useEffect(() => {

    }, []);

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">Diagramme des revenues</CardTitle>
                <p className="card-category">
                    Statistique de l'année {currentYear} en Euros
                </p>
            </CardHeader>
            <CardBody>
                <Line
                    data={FeeDiagramOfYear()}
                    options={options}
                    width={400}
                    height={185}
                />
            </CardBody>
            <CardFooter>
                <hr/>
                <div className="stats row justify-content-between pl-3 pr-3">
                    <div className="text-uppercase cursor-pointer">
                        {loading
                            ? <Spinner animation="grow" size="sm"/>
                            : <i className="fas fa-sync-alt" onClick={() => checkIncomeByYear()}/>}
                    </div>
                    <div className="text-dark">
                        <i className="nc-icon nc-minimal-left cursor-pointer" onClick={() => changeYear(-1)}/>
                        <i className="nc-icon nc-simple-delete"/>
                        <i className="nc-icon nc-minimal-right cursor-pointer" onClick={() => changeYear(+1)}/>
                    </div>
                </div>
            </CardFooter>
        </Card>
    );
}

export default FeeDiagrams;
