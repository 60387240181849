import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Card, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap";

function UserStats() {
    const [dj, setDj] = useState(0);
    const [dancers, setDancers] = useState(0);
    const [musician, setMusician] = useState(0);
    const [loading, setLoading] = useState(false);
    const [auditorPRO, setAuditorPRO] = useState(0);
    const [audioVisual, setAudioVisual] = useState(0);

    const checkUserStat = useCallback(async () => {
        setLoading(true)
        await axios.get('/admin/users/stats').then((response) => {
            let data = response.data;
            setDj(data['dj_stat'])
            setDancers(data['dancers_stat'])
            setMusician(data['musician_stat'])
            setAuditorPRO(data['auditor_pro_stat'])
            setAudioVisual(data['audio_visual_stat'])
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const total = () => {
        return  audioVisual + auditorPRO + dj + musician + dancers
    }

    useEffect(() => {
        checkUserStat().then(() => null);
    }, [checkUserStat]);

    useEffect(() => {

    }, []);

    return (
        <Card className="card-stats">
            <CardBody>
                <p className="text-center text-uppercase">nombre d'utilisateurs</p>
                <hr />
                <Row>
                    <Col md="6" xs="5">
                        <div className="row justify-content-between ml-1 mr-1">
                            <em className="mr-1">Dj</em>
                            <p>{dj}</p>
                        </div>
                        <div className="row justify-content-between ml-1 mr-1">
                            <em className="mr-1">Danseurs</em>
                            <p>{dancers}</p>
                        </div>
                        <div className="row justify-content-between ml-1 mr-1">
                            <em className="mr-1">Auditor Pro</em>
                            <p>{auditorPRO}</p>
                        </div>
                        <div className="row justify-content-between ml-1 mr-1">
                            <em className="mr-1">Audio Visuel</em>
                            <p>{audioVisual}</p>
                        </div>
                        <div className="row justify-content-between ml-1 mr-1">
                            <em className="mr-1">Musicien</em>
                            <p>{musician}</p>
                        </div>
                    </Col>
                    <Col md="6" xs="7">
                        <div className="numbers">
                            <p className="card-category">Total</p>
                            <h5 tag="p"><em>{total()}</em></h5>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <hr/>
                <div className="stats row justify-content-between pl-3 pr-3">
                    <div className="text-uppercase cursor-pointer">
                        {loading
                            ? <Spinner animation="grow" size="sm"/>
                            : <i className="fas fa-sync-alt" onClick={() => checkUserStat()}/>}
                    </div>
                    <i className="nc-icon nc-globe text-warning pt-1"/>
                </div>
            </CardFooter>
        </Card>
    );
}

export default UserStats;
