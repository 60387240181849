import dayjs from "dayjs";
import React, {useEffect} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";

function ArtistBank(props) {
    const {bank} = props

    useEffect(() => {

    }, []);

    return (
        <div>
            {bank && Object.keys(bank).length !== 0 &&
            <Card className="card-stats m-2">
                <CardBody>
                    <Row>
                        <Col md="4" className="text-break">
                            stripeID
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {bank?.stripe_account_id}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            Nom
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {bank?.lastname}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            Prénom
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {bank?.name}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            Email
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {bank?.email}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            Pays
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {bank?.country}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            Devise
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {bank?.currency}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            IBAN
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {bank?.iban}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            Swift
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {bank?.swift || 0}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            Dernière modification
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {dayjs(bank?.modified_at).format("DD-MM-YYYY")}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="text-break">
                            crée le
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {dayjs(bank?.created_at).format("DD-MM-YYYY")}
                            </p>
                        </Col>
                    </Row>
                </CardBody>
            </Card>}
        </div>
    );
}

export default ArtistBank;
