import axios from "axios";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row} from "reactstrap";
import {changeFields, notify} from "../tools/tools";
import NotificationAlert from "react-notification-alert";

function Login() {

    const history = useHistory();
    const notificationAlert = useRef()
    const isMounted = useRef(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');

    const auth = () => {
        if (!email?.length || !password?.length) {
            notify('danger', "email ou mot de passe incorrect", notificationAlert)
        } else {
            setLoading(true)
            axios.post("users/login", {email, password}).then(async response => {
                await localStorage.setItem('creativeAdminToken', JSON.stringify(response.data['token']));
                axios.defaults.headers.common['Isl-Token'] = response.data['token']
                await setLoading(false)
                history.push('/admin/dashboard');
            }).catch(error => {
                setLoading(false)
                notify('danger', "email ou mot de passe incorrect", notificationAlert)
            })
        }
    }

    const handleUserKeyPress = useCallback(event => {
        const {key, keyCode} = event;

        if (email?.length && password?.length && keyCode === 13 && key === 'Enter') {
            auth()
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [email, password]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);

        return () => {
            isMounted.current = true
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div className="row justify-content-center pt-5">
            <NotificationAlert ref={notificationAlert} />
            <Card className="card-user col-md-4">
                <CardHeader>
                    <CardTitle tag="h5">Connexion</CardTitle>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Email</label>
                                    <Input value={email} placeholder="Email" type="text"
                                           onChange={(e) => changeFields(setEmail, e)}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Mot de passe</label>
                                    <Input value={password} placeholder="Mot de passe" type="password"
                                           onChange={(e) => changeFields(setPassword, e)}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <div className="ml-auto mr-auto cursor-pointer">
                                <Button className="btn-round" disabled={loading} color="primary" onClick={() => auth()}>
                                    Connexion
                                </Button>
                            </div>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
}

export default Login;
