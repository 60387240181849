import dayjs from "dayjs";
import React, {useEffect} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {checkUnity} from "../../../tools/tools";

function Details(props) {

    const {service, currency, serviceCountry} = props

    useEffect(() => {

    }, []);

    return (
        <Card className="card-stats m-2">
            <CardBody>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Titre</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{service?.title}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Note</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{service?.notes || 0}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Pays</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{service?.country}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Temps de préparation</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">
                            {service?.preparation_time}&nbsp;
                            {checkUnity(service?.unit_of_the_preparation_time)}
                        </p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Durée de la prestation</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">
                            {service?.duration_of_the_service}&nbsp;
                            {checkUnity(service?.unit_duration_of_the_service)}
                        </p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Les villes d'activités</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">
                            {service?.cities && service?.cities[0] === 'all'
                                ? "Partout"
                                : service?.cities?.join(", ")}
                        </p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Liste de(s) materiel(s)</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">
                            {service?.materials?.list_of_materials?.length
                            && service?.materials?.list_of_materials[0] !== ''
                                ? service?.materials?.list_of_materials.join(", ")
                                : 0
                            }
                        </p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Évènement(s) possible</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{service?.events?.join(", ")}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Thematique(s)</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-right">{service?.thematics?.join(", ")}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Transport</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-primary text-right">
                            {!service?.travel_expenses?.from
                                ? <span className="text-red">Gratuit</span>
                                : <div>
                                    {service?.travel_expenses?.from && service?.travel_expenses?.to
                                        ?
                                        <p>entre {service?.travel_expenses?.from}&nbsp;
                                            {currency ? serviceCountry?.currencies?.symbol : serviceCountry}
                                            &nbsp;jusqu'a {service?.travel_expenses?.to}&nbsp;
                                            {currency ? serviceCountry?.currencies?.symbol : serviceCountry}
                                        </p>
                                        :
                                        <span className="text-red">
                                            {service?.travel_expenses?.from}&nbsp;
                                            {currency ? serviceCountry?.currencies?.symbol : serviceCountry}
                                        </span>
                                    }
                                </div>
                            }
                        </p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        <small>Dernière modification</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-primary text-right">
                            {dayjs(service?.modified_at).format("DD-MM-YYYY")}
                        </p>
                    </Col>
                </Row>
                <Row className={currency ? "mb-3" : ""}>
                    <Col md="4" className="text-break">
                        <small>Crée le</small>
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-primary text-right">
                            {dayjs(service?.created_at).format("DD-MM-YYYY")}
                        </p>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default Details;
