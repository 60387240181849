import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Col,
    Button,
    Row,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText, Spinner
} from "reactstrap";
import ContactCard from "../components/Main/contactCards/contactCard";
import {currencyByAddress} from "../tools/tools";

function ContactCards() {

    const [loading, setLoading] = useState(true);
    const [currency, setCurrency] = useState('');
    const status = useSelector(state => state.global.status);
    const countries = useSelector(state => state.global.countries);
    const [contactCard, setContactCard] = useState([]);
    const [contactCardToShow, setContactCardToShow] = useState({});

    const checkLastContactCard = useCallback(async () => {
        setLoading(true)
        await axios.get('/admin/contact_cards/per_page/1').then((response) => {
            let data = response.data.data;
            if (data.length)
                toShowContact(data[0])
            else setContactCardToShow({})
            setContactCard(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const toShowContact = (card) => {
        setCurrency(currencyByAddress(card?.reservation?.address, countries))
        setContactCardToShow(card)
    }

    const searchContactCard = async (e) => {
        let str = e.target.value;
        if (str) {
            setLoading(true)
            await axios.get('/admin/contact_cards/search/' + str).then((response) => {
                let data = response.data['contact_cards'];
                if (data.length)
                    toShowContact(data[0])
                else setContactCardToShow({})
                setContactCard(data)
                setLoading(false)
            })
        }

    }

    useEffect(() => {
        checkLastContactCard().then(() => null);
    }, [checkLastContactCard]);

    useEffect(() => {

    }, []);

    return (
        <div className="content">
            <Row>
                <Col md="6">
                    <Card className="card-stats">
                        <CardHeader>
                            <form>
                                <InputGroup className="no-border">
                                    <Input placeholder="Chercher la prise de contact, reservation, reference, addresse, ..."
                                           onChange={(e) => searchContactCard(e)}
                                           style={{width: '80%'}}/>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <i className="nc-icon nc-zoom-split ml-2"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </form>
                        </CardHeader>
                        <CardBody>
                            <Row className="mb-3 text-uppercase text-muted">
                                <Col md="3" className="text-left">
                                    <small>Auditeur</small>
                                </Col>
                                <Col md="3" className="text-left">
                                    <small>Artiste</small>
                                </Col>
                                <Col md="3" className="text-left">
                                    <small>Status</small>
                                </Col>
                                <Col md="3" className="text-left">

                                </Col>
                            </Row>
                            {!contactCard.length
                                ? <p className="text-left">Pas de prise de contact à afficher</p>
                                : contactCard.map((card, index) =>
                                    <Row key={index}>
                                        <Col md="3" className="text-left pt-3">
                                            {card.name}
                                        </Col>
                                        <Col md="3" className="text-left pt-3">
                                            {card.reservation.artist_name}
                                        </Col>
                                        <Col md="3" className="text-left pt-3">
                                            {status[card.status]}
                                        </Col>
                                        <Col md="3" className="text-left">
                                            <Button
                                                onClick={() => setContactCardToShow(card)}
                                                color={contactCardToShow.id === card.id ? "success" : "dark"}>
                                                afficher
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            }
                        </CardBody>
                        <CardFooter>
                            <hr/>
                            <div className="stats row justify-content-between pl-3 pr-3">
                                <div className="text-uppercase">
                                    {loading
                                        ? <Spinner animation="grow" size="sm"/>
                                        : <i className="fas fa-sync-alt" onClick={() => checkLastContactCard()}/>}
                                </div>
                                <i className="far fa-clock text-warning pt-1"/>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="6">
                    {Object.keys(contactCardToShow).length !== 0
                        ? <ContactCard contactCard={contactCardToShow} currency={currency}/>
                        : <p className="text-center">Pas de contact a afficher</p>
                    }
                </Col>
            </Row>
        </div>
    );
}

export default ContactCards;
